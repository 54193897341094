import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { useAppContext } from "@common/context/AppContext";
import { getDefaultEventAttributes } from "@common/utils/analytics";
const useAnalytics = () => {
  const {
    clevertapModule
  } = useAppContext();
  const pathname = usePathname();
  const handleAnalyticsEvent = (eventName: string, attributes = {}) => {
    try {
      if (window && window.mixpanel && clevertapModule) {
        window.mixpanel.track(eventName, {
          ...attributes,
          ...getDefaultEventAttributes()
        });
        if (eventName === "Page View") {
          window.mixpanel.time_event("Page Exit");
        }
        clevertapModule.event.push(eventName, {
          ...attributes,
          ...getDefaultEventAttributes()
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // click event
    const handleClick = (e: any) => {
      if (e?.target?.id && window.mixpanel && clevertapModule) {
        handleAnalyticsEvent("Button Click", {
          "Click ID": e.target.id,
          "Click Text": e.target.innerText,
          "Page Path": pathname,
          "Page Domain": window.location.origin
        });
      }
    };
    window.document.addEventListener("click", handleClick);
    return () => {
      window.document.removeEventListener("click", handleClick);
    };
  }, [clevertapModule]);
  return {
    handleAnalyticsEvent
  };
};
export default useAnalytics;